<template>
  <div class="container">
  <div >
          <button
          class="btn btn-primary btn-margin"
          @click="removeCard()">
          removeCard
        </button>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Uniswap',
  data () {
    return {
     files: []
    }
  },
  methods: {
      removeCard() {
        this.$store.commit('clearCoins')
      },

  },
    computed: {
      ...mapState(['currencies'])
    },
  created() {
    this.currencies = []
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>